import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import Home from './pages/Home';
import Search from './pages/Search';
import Profile from './pages/Profile';
import Create from './pages/Create'; 
import Navbar from './components/Navbar'; 
import Login from './pages/Login';
import Signup from './pages/Signup';
import Post from './postComponents/Post';
import Commentspage from './pages/Commentpage';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Othersprofile from './pages/Othersprofile';
import Feedback from './pages/Feedback';

function App() {
  const [data, setData] = useState([]);

  return (
    <BrowserRouter>
      <div className="app-container">
        <Navbar className="navbar" />
        <div className="content-container">
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/home" element={<Home/>} />
              <Route path="/search" element={<Search />} />
              <Route path="/create" element={<Create />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/:id" element={
                <Othersprofile
                photo="https://m.timesofindia.com/photo/104472511/104472511.jpg"
                username="JaneDoe456"
                handle="janedoe456"
                bio="Photographer | Traveler | Foodie"
                points={4321}
                followers={6543}
                following={2345}
              />
                } />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/openup/:feedback_uniqueid" element={<Feedback />} />
              <Route path="/post/:postid" element={<Post />} />
              <Route path="/comments/:id" element={<Commentspage/>} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
